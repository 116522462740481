export default {
    name: "index",
    data() {
        return {
            banners: [
                'http://wcspublic.oss-cn-hangzhou.aliyuncs.com/images/learning/pc/banner2.jpeg'
            ],
            imgs: [
                'http://public.wencaishui.com/images/learning/pc/index-01.png',
                'http://public.wencaishui.com/images/learning/pc/index-02-1.png',
                'http://public.wencaishui.com/images/learning/pc/index-02-2.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg5a749gUoyJP73wUwjAE4jAE.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg46749gUo6oro4AcwpAE4GQ.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg46749gUo96KP6AIwjAE4jAE.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg46749gUosv3fYDCPATgb.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg46749gUo48GmowcwjAE4jAE.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg46749gUokNm36wIwjAE4jAE.png.webp',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAg3bH49gUokPOllAYwpgQ4hQY.png.webp'
            ],

            intro: [
                {
                    eng: 'ABOUT US',
                    title: '财税实务训练专家',
                    desc: '专注于帮助各类财税服务机构与组织、财税工作者持续提升财税实务能力。通过实施有计划、可监督、可衡量的培养与训练活动，使得组织内员工的专业财税知识、技能、方法以及工作的价值观得到不断的改善与提高，从而能够发挥出最大的潜力，提高个人与组织的业绩，推动个人与组织的不断进步，实现个人与组织的双重发展。',
                    desc2: '相对于传统的公开课程培训，我们更注重组织内员工学习与训练效果的持续提升与保障。专业财税课程的设计制作、强大的监督式在线学习平台、丰富的内容与工具，是我们与客户一起成长的关键。'
                },{
                    eng: 'OUR SOLUTION',
                    title: '财税组织内训解决方案',
                    desc: '您需要一套完善的财税内训解决方案，系统化、有方向、有条理的去做好组织内员工专业能力的建设。'
                },{
                    eng: 'WHY CHOOSE US',
                    title: '为什么选择我们',
                    desc: '依托于财税服务领域10+年的经验、知识与内容积累，150+人的专业财税服务与技术研发团队，以及与各级政府机关长期的良好合作关系，我们始终以“让财税更简单，让服务更专业”作为企业使命。截至2021年，我们已经累计为10万+财税服务与管理人员提供过各类财税咨询、教育、辅导服务，并为多家财税服务机构提供长期合作的员工内训与能力提升服务。',
                    desc2: '· 累计超过2000+财税实战课程，2万+财税实务问题',
                    desc3: '· 为10万+各类财税从业与服务人员提供咨询培训服务',
                    desc4: '· 2020年，发布专业的在线财税内训平台--"学税通"',
                },{
                    eng: ' CUSTOMER FEEDBACK',
                    title: '客户满意是我们的动力',
                }, {
                    eng: 'PARTNER COMPANY',
                    title: '长期合作的企业客户',
                }
            ],

            process: [
                {
                    title: '专业能力',
                    desc: '财税咨询｜财税训练课程设计｜课件制作｜能力提升计划｜紧跟政策热点',
                },{
                    title: '强大的平台',
                    desc: '可管理、可监督、可衡量、可评估的组织内员工内训与学习平台｜手机端+PC端融合',
                },{
                    title: '完善的内容库',
                    desc: '完善的政策法规库｜5万+财税实务问答库｜持续更新的财税实操微课库｜丰富的工具',
                },{
                    title: '智能化评估与提升',
                    desc: '学习训练效果评估分析｜学习效果可视化｜智能化、针对性的持续改进与提升',
                }
            ],
            staffs: [
                {
                    avator: 'http://public.wencaishui.com/images/learning/pc/customer.jpeg',
                    name: '王总',
                    title: '财税咨询公司总经理',
                    desc: '学税通的课程内容专注在财税实务，非常适合我们对员工进行持续培训，相比去参加昂贵的各类线下课程，性价比非常高。'
                }, {
                    avator: 'http://public.wencaishui.com/images/learning/pc/customer.jpeg',
                    name: '徐处长',
                    title: '某税务服务部门主管',
                    desc: '传统的人员培训我们一般是采用公开线下培训的方式，缺乏针对性和评估手段，也很难确保培训效果。学税通很好的帮助我们解决这样的问题。'
                }, {
                    avator: 'http://public.wencaishui.com/images/learning/pc/customer.jpeg',
                    name: '谢经理',
                    title: '某公司财务总监',
                    desc: '非常适合我们对部门办税人员进行长期训练和提升，课程紧跟政策，内容也很丰富，工具使用也非常简单，能够很好的帮助确保训练的效果。'
                }
            ],
            companies: [
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
                '//24181030.s61i.faiusr.com/4/AD0IpvLDCxAEGAAgorf49gUonvqcMjDIAThk.png',
            ]
        }
    },
    computed: {
        optionRight() {
            return {
                direction: 3,
                limitMoveNum: 6
            }
        }
    }
}
